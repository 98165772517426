<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Ürün Kartları</b-card-title>
        <b-button
          variant="primary"
          :to="'/app/products/add/'"
        >
          <feather-icon icon="PlusIcon" />
          Oluştur
        </b-button>
      </b-card-header>
      <b-table
        v-if="dataList.length > 0 "
        responsive="sm"
        :fields="fields"
        :items="dataList"
        striped
      >
        <template #cell(title)="data">
          {{ data.item.title }} <span
            v-if="data.item.en_title"
            class="text-secondary"
          >/ <span class="text-info">{{ data.item.en_title }}</span></span>
          <div v-if="data.item.product_groups">
            <small class="text-warning">{{ data.item.product_groups }}</small>
          </div>
        </template>
        <template #cell(control)="data">
          <div class="text-right">
            <list-buttons
              primary-text="Görüntüle"
              :primary-action="'/app/products/view/' + data.item.id"
              :edit-action="'/app/products/edit/' + data.item.id"
              :delete-action="removeData"
              :data-id="data.item.id"
            />
          </div>
        </template>
      </b-table>
      <b-alert
        v-else
        show
        variant="info"
        class="mr-2 ml-2"
      >
        <div class="alert-body text-center">
          <div class="lead">
            Ürün kaydı bulunamadı.
          </div>
          <div class="mt-1">
            <b-button
              variant="info"
              size="sm"
              :to="'/app/products/add/'"
            >
              Yeni Kayıt Oluştur
            </b-button>
          </div>
        </div>
      </b-alert>
      <b-card-footer>
        <b-pagination
          v-model="currentPage"
          :total-rows="dataCounts"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-card-footer>
    </b-card>
  </div>
</template>
<script>
import {
  BTable, BCard, BCardHeader, BCardTitle, BButton, BAlert, BPagination, BCardFooter,
} from 'bootstrap-vue'
import ListButtons from '@/layouts/components/common/ListDropdownButtons.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'Products',
  components: {
    BCardFooter,
    BPagination,
    BAlert,
    ListButtons,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BButton,
  },
  data() {
    return {
      currentPage: 1,
      perPage: this.$store.state.app.perPage,
      fields: [
        {
          key: 'pcode',
          label: 'Ürün Kodu',
          thClass: 'text-nowrap width-200',
          tdClass: 'text-nowrap width-200',
        },
        {
          key: 'title',
          label: 'Ürün',
        },
        {
          key: 'username',
          label: 'Ekleyen',
          thClass: 'text-nowrap width-200',
          tdClass: 'text-nowrap width-200',
        },
        {
          key: 'control',
          label: '',
          thClass: 'text-nowrap width-50',
        },
      ],
      dataQuery: {
        select: [
          'products.id as id',
          'products.title as title',
          'products.en_title as en_title',
          'products.pcode as pcode',
          'users.name as username',
          'product_groups.title as product_groups',
          'products.created as created',
        ],
        limit: this.$store.state.app.perPage,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['products/dataList']
    },
    dataCounts() {
      return this.$store.getters['products/dataCounts']
    },
    saveData() {
      return this.$store.getters['products/dataSaveStatus']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * this.$store.state.app.perPage
      this.getData()
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.getData()
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('products/getDataList', this.dataQuery)
    },
    removeData(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('products/removeData', id)
          this.$swal({
            icon: 'success',
            title: this.$store.state.app.removeResultTitle,
            text: this.$store.state.app.removeResultMessage,
            confirmButtonText: this.$store.state.app.removeResultClose,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },

  },
}
</script>
